import React, {useContext} from 'react';
import {UserInformationContext} from "../../Context/index";
import {MetaTrainingDetails} from "./metaTrainingDetails";
import {Status} from "../utils";
import {getTranslatedLanguage, websiteTexts} from "../../utilities/languageHandler";
import {IUserInformationContext} from "../../models/interfaces";

export function TrainingList() {
    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);
    const trainingLists =  [
        {title: getTranslatedLanguage(websiteTexts.PENDING_TRAININGS, getUserInformationContext.currentLanguage), statuses: [Status.INCOMPLETE]},
        {title: getTranslatedLanguage(websiteTexts.COMPLETED_TRAININGS, getUserInformationContext.currentLanguage), statuses: [Status.COMPLETED]},
        {title: getTranslatedLanguage(websiteTexts.UPCOMING_TRAININGS, getUserInformationContext.currentLanguage), statuses: [Status.UPCOMING]},
        {title: getTranslatedLanguage(websiteTexts.EXPIRED_TRAININGS, getUserInformationContext.currentLanguage), statuses: [Status.EXPIRED]}
    ];

    
    function getTrainings(listOfTrainings: any, trainingTypes: string[]): Array<JSX.Element> {
        return listOfTrainings
        .map((trainingDetails: any, index: number) => {
            return getReturnElement(index, trainingDetails.name, trainingDetails.id, trainingDetails.status, trainingDetails.lastDate, trainingDetails.priority, trainingTypes);  
        })
        .filter((t:any) => t !== undefined)
    }

    function getTrainingList(listTitle: string, listStatuses: Array<Status>, index: number): object | undefined{
        const trainingElements = getTrainings(getUserInformationContext.userInfo.trainings, listStatuses)
        if (trainingElements.length > 0)
        return (
            <div className="row mt-4" key={index}>
            <div className="col-md-8 offset-md-2">
                <h4 className="heading-type" style={{textAlign:"center"}}>{listTitle}</h4>
                {
                    trainingElements
                }
            </div>
            </div> 
        )
        else return undefined
    }

    

    function getReturnElement(index: number, trainingName: string, trainingId: string, status: Status, lastDate: string, priorityRank: number, checkFor: string[]): JSX.Element | undefined  {
        if (checkFor.includes(status)) {
            return <MetaTrainingDetails
                    key={index}
                    trainingIndex={index}
                    trainingName={trainingName}
                    status={status}
                    lastDate={lastDate}
                    priorityRank={priorityRank}
                    trainingId={trainingId}
                />    
        } else {
            return undefined
        }
    }
    const userTrainingLists = trainingLists
        .map((tList, index: number) => getTrainingList(tList.title, tList.statuses, index))
        .filter(tList => tList !== undefined);
    return (
        <div className="container-height container-fluid justify-content-center main-container">
            {userTrainingLists.length === 0 ? <div><h4>You don't seem to have trainings assigned at this point. Please contact admin.</h4></div> : userTrainingLists}   
        </div>
    );
}
