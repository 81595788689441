import React, {useEffect, useState} from "react";
import {PageTypes} from "../../utils";
import {
    getFinishPage,
    getInfoImage,
    getInfoText,
    getInfoVideo,
    getIntroductionPages,
    getQuiz,
    getQuizResult
} from "../utils";
import "./styles.css";
import {makePostRequest} from "../../../ApiCalls";
import {IFeedbackDetails, IPages, IQuizAnswers, IQuizResults, IUserAnswers} from "../../../models/interfaces";
import {Button, Modal} from "react-bootstrap";
import {getTranslatedLanguage, websiteTexts} from "../../../utilities/languageHandler";

interface IProps {
    pageDetails: any;
    currentPageIndex: number;
    handleClickNext: any;
    reattemptQuiz: any;
    trainingName: string;
    trainingId: string;
    lastQuizResultPageId: number | null;
    getUpdatedTrainingDetails: any;
    currentLanguage: string;
    nextButtonActive: boolean;
}

interface InfoModalState {
    text: string,
    show: boolean
}

let quizDetails: any = {};
let quizResults: IQuizResults[] = [];

export function Content(props: IProps) {

    // prevent unload if a training is running.
    const refreshWarning = (e: BeforeUnloadEvent) => {
        e.preventDefault(); 
        e.returnValue = ''; // for chrome
        return "Refreshing page will erase progress. Proceed ?"
    };

    useEffect(() => {
        window.addEventListener('beforeunload', refreshWarning);
        return () => {window.removeEventListener('beforeunload', refreshWarning);}
    }, []);
    
    const [confettiExplosion, updateConfettiExplosion] = useState<boolean>(false);
    const [userAnswers, setAnswers] = useState<IUserAnswers>({});
    const [loading, handleLoadingStateChange] = useState<boolean>(false);
    const [totalCorrectAns, setCorrectAnswers] = useState<any | any>({});
    const [feedbackModalActive, handleFeedbackModal] = useState<boolean>(false);
    const [feedbackDetails, handleFeedBackTextChange] = useState<IFeedbackDetails>({text: "", loading: false, submitted: false, error: null});
    const [infoModalState, updateInfoModal] = useState<InfoModalState>({show: false, text: ""});
    let [isRedirectable, handleRedirectStatusChange] = useState<boolean>(false);
    
    function handleAnswerChange(key: string, questionId: number, hasMultipleAnswers: boolean) {
        let currentAnswers: IUserAnswers = userAnswers;
        const quizId: number = props.pageDetails.id;
        if (!currentAnswers.hasOwnProperty(quizId)) {
            currentAnswers[quizId] = {};
        }
        if (currentAnswers[quizId].hasOwnProperty(questionId)) {
            if (currentAnswers[quizId][questionId].includes(key)) {
                currentAnswers[quizId][questionId].splice(currentAnswers[quizId][questionId].indexOf(key), 1)
                if (currentAnswers[quizId][questionId].length === 0) {
                    delete currentAnswers[quizId][questionId];
                }
            } else if (!hasMultipleAnswers) {
                currentAnswers[quizId][questionId] = [key];
            } else {
                currentAnswers[quizId][questionId].push(key);
            }
        } else {
            currentAnswers[quizId][questionId] = [key];
        }
        setAnswers((prevState: any) => {
            return {
                ...currentAnswers,
            }
        })
    }

    function getInfoModal(modalState: InfoModalState, updateModal: React.Dispatch<React.SetStateAction<InfoModalState>>){
        const deactModal = {show: false, text: ""};
        return (<Modal show={modalState.show} onHide={() => updateModal(deactModal)} centered>
                    <Modal.Header>
                        <span className="modal-qs">{modalState.text}</span>
                    </Modal.Header>
                    <Modal.Body>
                        <Button className="float-right modal-option info-modal-button" onClick={() => updateModal(deactModal)}> OK </Button>
                    </Modal.Body>
                </Modal>)
    }

    function submitFeedback(feedback: IFeedbackDetails) {
        if (feedback.text.trim() !== "")  {
            let body = {
                text: feedback.text
            };
            handleFeedBackTextChange({...feedback, loading: true})
            makePostRequest(`/storeUserFeedback/${props.trainingId}`, body)
            .then((response) => {
                handleFeedBackTextChange({...feedback, loading: false, submitted: true});
                handleRedirect();
            })
            .catch((err) => {
                handleFeedBackTextChange({...feedback, loading: false, submitted: false, error: err})
            })
        }
    }
    function getContentBody(pageDetails: IPages | any, trainingName: string) {
        const quizId = pageDetails.type === PageTypes.QUIZ ? pageDetails.id
                       : pageDetails.type === PageTypes.QUIZ_RESULTS ? pageDetails.quizPageId : null;
        const userAnswersAgainstQuizId: IQuizAnswers = quizId && userAnswers.hasOwnProperty(quizId) ? userAnswers[quizId] : {};
        if (pageDetails.type === PageTypes.WELCOME || pageDetails.type === PageTypes.SECTION_START) return getIntroductionPages(trainingName, pageDetails.type, pageDetails.mdText,  props.currentLanguage);
        if (pageDetails.type === PageTypes.QUIZ) return getQuiz(pageDetails.questions, userAnswersAgainstQuizId, props.currentLanguage, handleAnswerChange);
        if (pageDetails.type === PageTypes.INFO_VIDEO) return getInfoVideo(pageDetails.videoUrl, pageDetails.mdText, props.currentLanguage);
        if (pageDetails.type === PageTypes.INFO_IMAGE) return getInfoImage(pageDetails.imageUrl, pageDetails.mdText);
        if (pageDetails.type === PageTypes.INFO_TEXT) return getInfoText(pageDetails.mdText);
        if (pageDetails.type === PageTypes.QUIZ_RESULTS) return getQuizResult(totalCorrectAns[quizId], quizDetails[quizId], reattemptQuiz, handleSubmit, pageDetails.quizPageId, userAnswersAgainstQuizId, loading, props.currentLanguage, confettiExplosion, updateConfettiExplosion);
        if (pageDetails.type === PageTypes.FINISH) return getFinishPage(feedbackDetails, handleFeedBackTextChange, submitFeedback, feedbackModalActive, handleFeedbackModal, isRedirectable, handleRedirect, loading, props.currentLanguage);
        return <div>Something went wrong. Please contact your training administrator.</div>
    }
    function handleRedirect() {
        handleLoadingStateChange(true);
        let getUpdatedUserDetails = props.getUpdatedTrainingDetails();
        getUpdatedUserDetails.then((status: boolean) => {
            handleLoadingStateChange(false);
            handleRedirectStatusChange(status);
        }).catch((status: boolean) => {
            handleLoadingStateChange(false);
            handleRedirectStatusChange(status);
        })
    }
    function handleNext(
        pageDetails: any, currentValues: any, pageType: string, currentPageIndex: number, updateInfoModal: React.Dispatch<React.SetStateAction<InfoModalState>>
        ) {
        let allowNext: boolean = true;
        if (pageType === PageTypes.QUIZ) {
            let quizId = pageDetails.id;
            let correctAnswers = 0;
            if (currentValues.hasOwnProperty(quizId)) {
                pageDetails.questions.forEach((obj: any) => {
                    if (currentValues[quizId].hasOwnProperty(obj.id)
                        && currentValues[quizId][obj.id].length === obj.answers.length
                        && JSON.stringify(currentValues[quizId][obj.id]) === JSON.stringify(obj.answers)
                    ) {
                        correctAnswers++;
                    }
                })
                quizDetails[quizId] = pageDetails;
                setCorrectAnswers({...totalCorrectAns, [quizId]: correctAnswers});
            }
            // TODO : why put the check after storing values ?
            if (!currentValues.hasOwnProperty(quizId) || Object.keys(currentValues[quizId]).length !== pageDetails.questions.length) {
                allowNext = false;
                updateInfoModal({show: true, text: getTranslatedLanguage(websiteTexts.YOU_NEED_TO_ANS_ALL_QUESTIONS, props.currentLanguage)})
            }
            updateConfettiExplosion(false);
        }
        if (allowNext) {
            window.scroll(0,0);
            props.handleClickNext(currentPageIndex);
        }
    }
    function reattemptQuiz(quizPageId: number) {
        setAnswers({...userAnswers, [quizPageId]: {}});
        props.reattemptQuiz(quizPageId);
    }
    function handleSubmit(body: any) {
        let currentQuizPageId = props.pageDetails.quizPageId;
        quizResults = quizResults.filter((obj) => obj.pageId !== currentQuizPageId);
        quizResults.push(
            {
                pageId: props.pageDetails.quizPageId,
                userAnswers: body,
            }
        );
        if (props.lastQuizResultPageId === props.pageDetails.id) {
            handleLoadingStateChange(true);
            makePostRequest(`/markTrainingComplete/${props.trainingId}`, {quizResults: quizResults})
                .then((response) => {
                    handleLoadingStateChange(false);
                    props.handleClickNext(props.currentPageIndex);
                })
                .catch((err) => {
                    handleLoadingStateChange(false);
                    alert("Some Error Occured!");
                })
        } else {
            props.handleClickNext(props.currentPageIndex);
        }
    }
    return (
        <div>
            {getInfoModal(infoModalState, updateInfoModal)}
            {getContentBody(props.pageDetails, props.trainingName)}
            {!(props.pageDetails.type === PageTypes.QUIZ_RESULTS || props.pageDetails.type === PageTypes.FINISH) &&
            
                <button className={`next-btn ${props.nextButtonActive || "btn-disabled"}`} disabled={!props.nextButtonActive}
                    onClick={() =>  handleNext(props.pageDetails, userAnswers, props.pageDetails.type, props.currentPageIndex, updateInfoModal)}
                >
                    {getTranslatedLanguage(websiteTexts.NEXT, props.currentLanguage)}
                </button>

            }
        </div>
    )
}