import React, {useContext, useEffect, useState} from "react";
import {UserInformationContext} from "../../../Context";
import { SideNav } from "./sideNav";
import {PageTypes} from "../../utils";
import {Content} from "./content";
import {token} from "../../../ApiCalls";
import "./styles.css";
import { Redirect } from "react-router-dom";
import {makeGetRequest, saveToken} from "../../../ApiCalls";
import {IPages, IUserInformationContext, IInfoImagePage} from "../../../models/interfaces";
import { getTranslatedLanguage, websiteTexts } from "../../../utilities/languageHandler";

const VIDEO_NEXT_BUTTON_DEACTIVATION_SEC = 180;

export function CurrentTraining(props: any) {
    const maxSideNavPagesToBeDisplayed = 10;
    const trainingIndex = props.location.state ? props.location.state.trainingIndex : null;
    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);
    const totalPages = trainingIndex !== null ? getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages.length : null;
    let [pageIndex, updatePageIndex] = useState<number>(0);
    let [navigablePageIndexes, setNavigablePageIndexes] = useState <number[]>([]);
    let [pageRangeOnSideNav, handlePageRangeOnSideNav] = useState <{from: number; to: number}>({from: 0, to: totalPages > maxSideNavPagesToBeDisplayed ? maxSideNavPagesToBeDisplayed : totalPages});
    let [lastQuizResultPageId, handleLastQuizResultPageIdChange] = useState<number | null>(null)
    let [nextButtonActive, updateNextButton] = useState<boolean>(true);

    function getImageListToPreload(pages: IPages[]): Array<JSX.Element>{
        return pages.filter((page) => page.type === PageTypes.INFO_IMAGE).map((page: any, index: number) => <img key={`${index}`} src={page.imageUrl} style={{display:"none"}}/> );
    }

    function getLastQuizResultPageId(pages: IPages[]): number {
        let pageId = null;
        pages.forEach((page: IPages) => {
            if (page.type === PageTypes.QUIZ_RESULTS) {
                pageId = page.id;
            }
        });
        return pageId;
    }
    function handleClickOnSidenav(index: number, navPageIndexes: number[]) {
        if (navPageIndexes.includes(index)) {
            updatePageIndex(index);
        }
    }
    function handlePageChange(currentPageIndex: number) {
        const isLastPage = totalPages === currentPageIndex + 1;
        if (!isLastPage) {
            if (getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages[pageIndex+1].type === PageTypes.INFO_VIDEO){
                updateNextButton(false);
                setTimeout(() => {updateNextButton(true)}, VIDEO_NEXT_BUTTON_DEACTIVATION_SEC*1000)
            }
            updatePageIndex((currentPageIndex + 1));
            if (!navigablePageIndexes.includes(currentPageIndex + 1)) {
                setNavigablePageIndexes(navigablePageIndexes.concat(currentPageIndex + 1))
            }
            handlePageRangeOnSideNav(getSideNavPageRange(totalPages, currentPageIndex))
        }
    }
    function handleReattemptQuiz(pageId: number) {
        let index = 0;
        for (const pageDetails of getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages) {
            if (pageDetails.id === pageId) {
                updatePageIndex(index)
                break;
            }
            index++;
        }
    }

    function getSideNavPageRange(totalSideNavPages: number, currentPageIndex: number): {from: number; to: number} {
        let to = Math.min(totalSideNavPages, currentPageIndex + 3);
        const from = Math.max(0, to - maxSideNavPagesToBeDisplayed);
        if(to-from < totalSideNavPages) to = from + maxSideNavPagesToBeDisplayed;
        return {
            to: to,
            from: from
        };
    }

    function getSideNav(): JSX.Element[] {
        return getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages.map((pageDetails: IPages, index: number) => {
            return <SideNav
                key={index}
                currentPageIndex={pageIndex}
                navigablePageIndexes={navigablePageIndexes}
                pageIndex={index}
                pageType={pageDetails.type}
                handleClick={(pageIndex: number, navigablePageIndexes: number[]) => handleClickOnSidenav(pageIndex, navigablePageIndexes)}
                currentLanguage = {getUserInformationContext.currentLanguage}
            />
        })
    }
    function changeCurrentLanguageToTrainingLanguage() {
        getUserInformationContext.handleLanguageChange(getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.language)
    }
    useEffect(() => {
        handleLastQuizResultPageIdChange(trainingIndex !== null ? getLastQuizResultPageId(getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages) : null)
        if (trainingIndex !== null) changeCurrentLanguageToTrainingLanguage();
    }, []);
    function getUpdatedTrainingDetails() {
        return new Promise((resolve, reject) => {
            let validateLogin = makeGetRequest("/getUserInfo", token);
            validateLogin.then((response: any) => {
                getUserInformationContext.updateUserInfo(response.data);
                saveToken(response.data.token);
                resolve(true);
            });
            validateLogin.catch((error) => {
                reject(false)
            })
        })
    }
    if (trainingIndex === null) {
        return <Redirect to="/"/>
    }
    const navElements = getSideNav().slice(pageRangeOnSideNav.from, pageRangeOnSideNav.to);
    navElements.push(<div className="total-pages mt-4 mb-2" key={"last_key"}>{getTranslatedLanguage(websiteTexts.TOTAL_PAGES, getUserInformationContext.currentLanguage)}: {totalPages-1}</div>)
    return (
        <div className="container-fluid training-container">
            <div className="preloader">{getImageListToPreload(getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages)}</div>
            <div className="row">
                <div className="col-sm-2 side-nav">
                {navElements}
                </div>
                <div className="col-sm-10 content">
                    <Content
                        pageDetails = {getUserInformationContext.userInfo.trainings[trainingIndex].trainJSON.pages[pageIndex]}
                        currentPageIndex = {pageIndex}
                        handleClickNext={(currentPageIndex: number) => handlePageChange(currentPageIndex)}
                        reattemptQuiz = {(pageId: number) => handleReattemptQuiz(pageId)}
                        trainingName={getUserInformationContext.userInfo.trainings[trainingIndex].name}
                        trainingId = {getUserInformationContext.userInfo.trainings[trainingIndex].id}
                        lastQuizResultPageId = {lastQuizResultPageId}
                        getUpdatedTrainingDetails={getUpdatedTrainingDetails}
                        currentLanguage = {getUserInformationContext.currentLanguage}
                        nextButtonActive = {nextButtonActive}
                    />
                </div>
            </div>
        </div>
    )
} 