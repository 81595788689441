import React, { useState } from "react";
import debbie from "../../images/debbiefull.png";
import "./styles.css"
import {Modal} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import {PageTypes} from "../utils";
import ReactMarkdown from "react-markdown";
import {
    IFeedbackDetails,
    IQuestions,
    IQuizAnswers,
    IQuizPage,
    IUserAnswersForSubmission
} from "../../models/interfaces";
import {getTranslatedLanguage, websiteTexts} from "../../utilities/languageHandler";
import { loadingGifB64 } from "../../images/loading";
import Confetti from 'react-dom-confetti';

function isAnswerChecked(userAnswers: IQuizAnswers, questionId: number, key: string) {
    return userAnswers.hasOwnProperty(questionId) && userAnswers[questionId].includes(key)
}
export function unescapeString(str: string) {
    return str
        .replace(/\\n/g, '\n')
        .replace(/\\\"/g, '\"')
        .replace(/\\f/g, '\f')
        .replace(/\\r/g, '\r')
        .replace(/\\t/g, '\t')
        .replace(/\//g, '\/')
}
function getIntroMarkdownComponent(text: string){
    return (
    <div style={{textAlign:"center"}}>
        <div className="mt-2 intro-markdown"><ReactMarkdown source={unescapeString(text)}/></div>
    </div>
)}

function getIntroWelcomeHeader(trainingName: string, currentLanguage: string){
    return (
        <div>
            <b style={{padding:"10px", fontSize:"1.2em"}}>{getTranslatedLanguage(websiteTexts.WELCOME_TO_THE, currentLanguage)}</b>
            <div style={{margin: "20px"}}>
                <div className="heading-type" style={{fontSize: "2em", padding: "10px", textAlign:"center"}}>{trainingName}</div>
            </div>
        </div>
)}

export function getIntroductionPages(trainingName: string,  pageType: PageTypes, text: string, currentLanguage: string) {
    return (
        <div className="welcome">
            <div className="float-left" style={{width:"15%"}}>
                <img src={debbie} alt="mascot"/>
            </div>
            <div className="float-right" style={{width:"85%"}}>
                {pageType === PageTypes.WELCOME ? getIntroWelcomeHeader(trainingName, currentLanguage) : <div/>}
                {getIntroMarkdownComponent(text)}
                <br/>
            </div>
        </div>
    )
}

export function getFinishPage(feedbackDetails: IFeedbackDetails,
                              handleFeedbackDetails: (feedbackDetails: IFeedbackDetails) => void,
                              submitFeedback: (feedbackDetails: IFeedbackDetails) => void,
                              modalActive: boolean,
                              handleModal: (modalActive: boolean) => void,
                              isRedirectAble: boolean,
                              handleRedirect: () => void,
                              loading: boolean, currentLanguage: string) {
     if (isRedirectAble) {
        return <Redirect to="/"/>;
     }
    return (
        <div className="mt-4 pt-4 suggestion-box text-center">
            <div>
                <h4>{getTranslatedLanguage(websiteTexts.THANKYOU_TRAINING_COMPLETION, currentLanguage)}</h4>
                <div>{getTranslatedLanguage(websiteTexts.DO_YOU_HAVE_QUS, currentLanguage)}</div>
                <div>
                    <textarea className="mt-2" value={feedbackDetails.text} onChange={(e) => handleFeedbackDetails({...feedbackDetails, text: e.target.value})}/>
                </div>
                {feedbackDetails.error && <div className="error-color">{feedbackDetails.error}</div>}
                <div>
                    <button className="mt-2 custom-btn"
                            onClick={feedbackDetails.text.trim() === "" ? () => handleModal(!modalActive) : () => submitFeedback(feedbackDetails)}
                            disabled={feedbackDetails.loading}
                    >
                        {feedbackDetails.loading ? getTranslatedLanguage(websiteTexts.SUBMITTING, currentLanguage) : getTranslatedLanguage(websiteTexts.SUBMIT, currentLanguage)}
                    </button>
                </div>
                {loading && feedbackDetails.submitted && <div>{getTranslatedLanguage(websiteTexts.PLEASE_WAIT_WHILE_LOADING, currentLanguage)}</div>}

                {/*Modal*/}
                <Modal show={modalActive} onHide={() => handleModal(!modalActive)} centered>
                    <Modal.Header>
                        <span className="modal-qs">{getTranslatedLanguage(websiteTexts.ARE_YOU_SURE_YOU_DONT_HAVE_ANY_QUESTION_FOR_US, currentLanguage) }</span>
                    </Modal.Header>
                    <Modal.Body>
                        {!loading &&
                        <div>
                            <div className="modal-option float-left" onClick={handleRedirect}>
                                {getTranslatedLanguage(websiteTexts.I_HAVE_NO_QUESTION, currentLanguage)}
                            </div>
                            <div className="float-right modal-option" onClick={() => handleModal(!modalActive)}>
                                {getTranslatedLanguage(websiteTexts.I_WOULD_LIKE_TO_ASK, currentLanguage)}
                            </div>
                        </div>
                        }
                        {loading && <div>{getTranslatedLanguage(websiteTexts.PLEASE_WAIT_WHILE_LOADING, currentLanguage)}</div>}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
function getMascotTextForResult(userPercentage: number, passPercent: number, currentLanguage: string) {
    const replaceAbleValues: any = {
        userPercentage,
        passPercent,
    };
    return  userPercentage >= passPercent ?
        getTranslatedLanguage(websiteTexts.QUIZ_COMPLETION_MESSAGE, currentLanguage)
        : getTranslatedLanguage(websiteTexts.QUIZ_REATTEMPT_MESSAGE, currentLanguage)
            .replace(/##userPercentage|##passPercent/gi, function(val: string){
                return replaceAbleValues[val.substring(2)];
            });
}
export function getQuizResult(totalCorrectAnswered: number, quizDetails: IQuizPage,
                              reattemptQuiz: (quizPageId: number) => void,
                              handleSubmit: (userAnswerForSubmission: IUserAnswersForSubmission[]) => void,
                              quizPageId: number, userAnswers: IQuizAnswers, loading: boolean,
                              currentLanguage: string,
                              confettiExplosion: boolean,
                              updateConfettiExplosion: (val: boolean) => void
) {
    const passPercent = quizDetails.passPercent;
    const totalQuestions = quizDetails.questions.length;
    const userPercentage = (passPercent && totalQuestions) ? (100/totalQuestions)*totalCorrectAnswered : 0;
    let userAnswerForSubmission: IUserAnswersForSubmission[] = [];
    if (userPercentage >= passPercent && !loading) {
        if (confettiExplosion === false) {
            setTimeout(() => {
                updateConfettiExplosion(true);
            }, 0)
        }
        userAnswerForSubmission = quizDetails.questions.map((questionObj: any) => {
            let newQuestionObject = {...questionObj};
            newQuestionObject["userAnswer"] = userAnswers[questionObj.id];
            return newQuestionObject;
        })
    }
    const confettiConfig: any = {
        width: "15px",
        height: "10px",
        duration: 3000,
        startVelocity: 60,
        spread: 150,
        elementCount: 200,
    };

    return (
        <div className="result text-center mt-4">
            <div style={{width: "60%", margin: "auto"}}>
                 <div className="row">
                     <div className="col-sm-3">
                        <img src={debbie} alt="mascot"/>
                    </div>
                    <div className="col-sm-9">
                        <div className="reattempt-text">
                            {getMascotTextForResult(Math.round(userPercentage), passPercent, currentLanguage)}
                        </div>
                        {passPercent !== 0 && <div>
                            <div className="heading-type" style={{marginTop:"10px"}}>{getTranslatedLanguage(websiteTexts.YOUR_SCORE, currentLanguage)}</div>
                            <h2 style={{color: (passPercent && userPercentage < passPercent) ? "red" : "green"}}>{Math.round(userPercentage)}%</h2>
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${userPercentage}%`}}/>
                            </div>
                        </div>}
                        <Confetti active={ confettiExplosion } config={confettiConfig}/>
                        {(userPercentage < passPercent) && <div>
                            <button className="mt-2 custom-btn"
                                onClick={() => reattemptQuiz(quizPageId)}
                            >{getTranslatedLanguage(websiteTexts.REATTEMPT, currentLanguage)}</button>
                        </div>}

                    </div>
                </div>
            </div>

            <div className="row">
                    <div className="col-sm-12 text-left">
                        {showCorrectAnswers(quizDetails.questions, userAnswers, currentLanguage)}
                    </div>
            </div>

            {userPercentage >= passPercent && <div>
                <button className="mt-4 mb-4 custom-btn"
                    onClick={() => handleSubmit(userAnswerForSubmission)}
                    disabled={loading}
                >{loading ? getTranslatedLanguage(websiteTexts.SUBMITTING, currentLanguage) : getTranslatedLanguage(websiteTexts.SUBMIT, currentLanguage)}</button>
            </div>}
        </div>
    )
}
function getBgColor(userAnswers: IQuizAnswers, correctAnswers: IQuizAnswers, questionId: number, option: string) {
    if (!userAnswers.hasOwnProperty(questionId) && correctAnswers[questionId].includes(option)) return "green";
    if (userAnswers.hasOwnProperty(questionId) && userAnswers[questionId].includes(option) && correctAnswers[questionId].includes(option)) return "green";
    if (userAnswers.hasOwnProperty(questionId) && userAnswers[questionId].includes(option) && !correctAnswers[questionId].includes(option)) return "red";
    if (userAnswers.hasOwnProperty(questionId) && !userAnswers[questionId].includes(option) && correctAnswers[questionId].includes(option)) return "green";
}
function showCorrectAnswers(questions: IQuestions[], userAnswers: IQuizAnswers, currentLanguage: string) {
    let correctAnswers: any = {};
    questions.forEach((question: any) => {
        correctAnswers[question.id] = question.answers;
    });
    return questions.map((questionObject: any, index: number) => {
        return (
            <div className="question-container mt-4 mb-4"
                key={index}
            >
                <span className="qsNo mb-4">{getTranslatedLanguage(websiteTexts.QUESTION, currentLanguage)} {index+1}</span>
                <div className="question mt-2"> <ReactMarkdown source={unescapeString(questionObject.mdText)}/></div>
                <div className="options mt-2">
                    {questionObject.question.trim() !== "" && <div className="wdyt">{questionObject.question}</div>}
                    {questionObject.options.map((option: any, ind: number) => {
                        return (
                            <button value={option.key}
                                key={ind}
                                className={`option-btn`}
                                style={{background: getBgColor(userAnswers, correctAnswers, questionObject.id, option.key)}}
                            > 
                                <i className={`ml-1 mr-2 fa ${userAnswers.hasOwnProperty(questionObject.id) &&  userAnswers[questionObject.id].includes(option.key) ? "fa-check-square-o" : "fa-square-o"}`}></i>
                                {option.optionText}
                            </button>
                        )
                })}
                </div>
                <br/>
                {questionObject.mdExplanation.trim() !== "" && <div>
                    <span className="qsNo"> {getTranslatedLanguage(websiteTexts.EXPLANATION, currentLanguage)} </span>
                    <div className="explanation mt-2"><ReactMarkdown source={unescapeString(questionObject.mdExplanation)}/></div>
                </div>}
            </div>
        )
    })
}
export function getQuiz(questions: IQuestions[], userAnswers: IQuizAnswers, currentLanguage: string,
                        handleChange?: (key: string, id: number, hasMultipleAnswers: boolean) => void,
) {
    return (
        <div className="question-container">
            {
                questions.map((questionObject: any, index: number) => {
                    return (
                        <div className="mt-4 mb-4"
                            key={index}
                        >
                            <span className="qsNo mb-4">{getTranslatedLanguage(websiteTexts.QUESTION, currentLanguage)} {index+1}</span>
                            {questionObject.mdText.trim() !== "" && <div className="question mt-2"> <ReactMarkdown source={unescapeString(questionObject.mdText)}/></div>}
                            <div className="options mt-2">
                            <div className="wdyt">{questionObject.question}</div>
                            {questionObject.options.map((option: any, ind: number) => {
                                return (
                                    <button value={option.key}
                                        key={ind}
                                        className={`option-btn ${isAnswerChecked(userAnswers, questionObject.id, option.key) ? "checked" : "unchecked"}`}
                                        onClick={handleChange ? () => handleChange(option.key, questionObject.id, questionObject.answers.length > 1) : undefined}
                                    >
                                        <span className={"option-circle"}>{option.key}</span>
                                        <i className={`ml-1 mr-2 fa ${isAnswerChecked(userAnswers, questionObject.id, option.key) ? "fa-check-square-o" : "fa-square-o"}`}/>
                                        {option.optionText}
                                    </button>
                                )
                            })}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

function getBasicTextElement(text: string){
    if(text.trim() !== "") return <div className="iframe-text text-left"><ReactMarkdown source={unescapeString(text)}/></div>
    else return <div></div>
}

export function getInfoVideo(videoUrl: string, text: string, currentLanguage: string) {
    if(videoUrl.includes('/watch?v=') && !videoUrl.includes('&')){
        console.log('Auto correcting a "watch" video to embedded. Might not work long term.');
        videoUrl = videoUrl.replace('/watch?v=', '/embed/');
    }
    if(!videoUrl.includes('?')) videoUrl = videoUrl + '?rel=0&controls=0' 
    else videoUrl = videoUrl + '&rel=0&controls=0'
    
    return (
        <div className="mt-4">
            <div className="mt-1 mb-2 text-center">
                <b>{getTranslatedLanguage(websiteTexts.PLEASE_WATCH_THE_VIDEO, currentLanguage)}</b>
            </div>
            
            <div className="holder text-center">
                <iframe title="info-video" width="700px" height= "480px" className="vidframe" src={videoUrl} allow={"accelerometer; encrypted-media; gyroscope;"}></iframe>
                <div className="topbar" onClick={undefined}></div>
                <div className="lowerbar"  onClick={undefined}></div>
            </div>
            {getBasicTextElement(text)}
        </div>
    )
}

interface ImageComponentProps {
    imageUrl: string;
    text: string;
}

function ImageComponent (props: ImageComponentProps)
{
    const [loaded, setLoaded] = useState<boolean>(false)
    return (
        <div className="mt-4">
            <div className="holder text-center">
                {loaded?null:<img className="info-image-loading" src={`data:image/gif;base64,${loadingGifB64}`}></img>}
                <img className="info-image-frame" alt="loading-image" src={props.imageUrl} onLoad={() => setLoaded(true)}/>
            </div>
            {getBasicTextElement(props.text)}
        </div>
    ) 
}


export function getInfoImage(imageUrl: string, text: string) {
    return (
        <ImageComponent imageUrl={imageUrl} text={text}/>
    )
}


export function getInfoText(text: string) {
    return (
        <div className="mt-4">
            {getBasicTextElement(text)}
        </div>
    )
}