export interface validationTemplate {
    status: boolean,
    message: string | null,
    type: string | null,
}

interface UserCredentials {
    userId: string | null,
    password: string | null;
}
export function getFormattedDate(date: string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const dateAsArray = date.split("-");
    return `${dateAsArray[2]} ${months[Number(dateAsArray[1])-1]} ${dateAsArray[0]}`
}
export enum PageTypes {
    WELCOME = "WELCOME",
    SECTION_START = "SECTION_START",
    QUIZ = "QUIZ",
    INFO_VIDEO = "INFO_VIDEO",
    INFO_IMAGE = "INFO_IMAGE",
    INFO_TEXT = "INFO_TEXT",
    QUIZ_RESULTS = "QUIZ_RESULTS",
    FINISH = "FINISH"

}
export enum Status {
    INCOMPLETE = "INCOMPLETE",
    UPCOMING = "UPCOMING",
    EXPIRED = "EXPIRED",
    COMPLETED = "COMPLETED",
}
export enum Fields {
    USER_ID = "USER_ID",
    PASSWORD = "PASSWORD",
}
export function validateLogin(userCredentials: UserCredentials) {
    const validation: validationTemplate = {
        status: false,
        message: null,
        type: null,
    }

    if (!userCredentials.userId) {
        validation.type = Fields.USER_ID;
        validation.message = "Please enter user id";
    } else if (!userCredentials.password) {
        validation.type = Fields.PASSWORD;
        validation.message = "Please enter password";
    } else {
        validation.status = true;
    }
    return validation;
}

export const testLoginResponseJson = {
	token: "1234512345" ,
	name: "Apoorv Kumar",
	company: {
        name: "org name",
        orgLoginId: "orgLoginId",
    },
	trainings: [ 
        { 
            trainingName: "Prevention of Sexual Harrasment Training (Employees)",
            status: "INCOMPLETE",
            priority: 5,
            lastDate: "2019-12-20",
            trainJSON: {
                name: "test name",
                pages: [
                    {id:1, type:"WELCOME",  mdText:"### In this test we will learn about....\n\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."},
               
                    {id:2, type:"SECTION_START",  mdText:"### In this section we'll see 1, 2 ,3\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
                    {id:3, type:"INFO_VIDEO", videoUrl: "", mdText: "This video talks about blah blah\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."},
               
                    {id:4, type:"QUIZ", passPercent: 80,
                    questions: [
                        {
                            id:1, mdText:"Consider this scenario ...",
                            question:"What is the answer to adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem?", 
                            answers: ["A"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                        },
                        {
                            id:2, mdText:"This is some other info like consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
                            question:"What is the answer to Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                        },
                        {
                            id:3, mdText:"Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                        },
                        {
                            id:4, mdText:"Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                        },
                        {
                            id:5, mdText:"Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                        }
                        
                    ]
                    },
                    {id:5, type:"QUIZ_RESULTS", quizPageId: 4 },
                    {id:6, type:"FINISH"}

                ]
            }
        
        },
        { 
            trainingName: "Training B - this has a super super long name which will exceed the length",
            status: "UPCOMING",
            priority: 5,
            lastDate: "2020-03-20",
            trainJSON: {
                name: "test name",
                pages: [
                    {id:1, type:"WELCOME",  mdText:"Welcome to the test"},
               
                    {id:2, type:"SECTION_START",  mdText:"#In this section we'll see 1, 2 ,3"},
               
                    {id:3, type:"QUIZ", passPercent: 80,
                    questions: [
                        {
                            id:1, mdText:"# Some Info",
                            question:"What is the answer?", answers: ["A"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "# The answers are correct because blah blah blah"
                        },
                        {
                            id:2, mdText:"# Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "# These answers are correct because blah blah blah"
                        }
                        
                    ]
                    },
                    {id:4, type:"INFO_VIDEO", videoUrl: "https://www.youtube.com/embed/f_r79d5hqZU?rel=0", mdText: "# This video talks about blah blah"},
               
                    {id:5, type:"QUIZ_RESULTS", quizPageId: 3 },
                    {id:6, type:"FINISH"}

                ]
            }
        
        },
        { 
            trainingName: "training C",
            status: "EXPIRED",
            priority: 5,
            lastDate: "2019-10-20",
            trainJSON: {
                name: "test name",
                pages: [
                    {id:1, type:"WELCOME",  mdText:"Welcome to the test"},
               
                    {id:2, type:"SECTION_START",  mdText:"#In this section we'll see 1, 2 ,3"},
               
                    {id:3, type:"QUIZ", passPercent: 80,
                    questions: [
                        {
                            id:1, mdText:"# Some Info",
                            question:"What is the answer?", answers: ["A"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "#The answers are correct because blah blah blah"
                        },
                        {
                            id:2, mdText:"# Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "#These answers are correct because blah blah blah"
                        }
                        
                    ]
                    },
                    {id:4, type:"INFO_VIDEO", videoUrl: "https://www.youtube.com/embed/f_r79d5hqZU?rel=0", mdText: "#This video talks about blah blah"},
               
                    {id:5, type:"QUIZ_RESULTS", quizPageId: 3 },
                    {id:6, type:"FINISH"}

                ]
            }
        
        },
        { 
            trainingName: "training D",
            status: "COMPLETE",
            priority: 5,
            lastDate: "2019-11-20",
            trainJSON: {
                name: "test name",
                pages: [
                    {id:1, type:"WELCOME",  mdText:"Welcome to the test"},
               
                    {id:2, type:"SECTION_START",  mdText:"#In this section we'll see 1, 2 ,3"},
               
                    {id:3, type:"QUIZ", passPercent: 80,
                    questions: [
                        {
                            id:1, mdText:"# Some Info",
                            question:"What is the answer?", answers: ["A"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "#The answers are correct because blah blah blah"
                        },
                        {
                            id:2, mdText:"# Some other info",
                            question:"What is the answer?", answers: ["B"], options: [{key: "A", optionText: "OPA"}, {key: "B", optionText: "OPB"}] ,
                            mdExplanation: "#These answers are correct because blah blah blah"
                        }
                        
                    ]
                    },
                    {id:4, type:"INFO_VIDEO", videoUrl: "https://www.youtube.com/embed/f_r79d5hqZU?rel=0", mdText: "#This video talks about blah blah"},
               
                    {id:5, type:"QUIZ_RESULTS", quizPageId: 3 },
                    {id:6, type:"FINISH"}

                ]
            }
        
        }
	]
}
