import axios from 'axios';

const errorTexts = {
    SOMETHING_WENT_WRONG_PLEASE_CONTACT_YOUR_ADMINISTRATOR: "Something went wrong! Please contact your administrator.",
    INVALID_USERNAME_OR_PASSWORD: "Invalid username or password!",
    FAILED_TO_CONNECT_TO_THE_SERVER: "Failed to connect to the server!"
};

const errorMessage: any = {
  ERROR_403: errorTexts.INVALID_USERNAME_OR_PASSWORD,
  ERROR_404: errorTexts.SOMETHING_WENT_WRONG_PLEASE_CONTACT_YOUR_ADMINISTRATOR,
  ERROR_500: errorTexts.SOMETHING_WENT_WRONG_PLEASE_CONTACT_YOUR_ADMINISTRATOR,
  TIME_OUT: errorTexts.FAILED_TO_CONNECT_TO_THE_SERVER,
};
const baseUrl = "https://asia-east2-upceed-269316.cloudfunctions.net";

export const idTokenName = "ucsTokenForLogin";
export const tokenUpdateTimeSec = "ucsTokenUpdateTime";

export let token: string = "";
export function saveToken(idToken: string) {
  if(getToken() !== idToken) window.localStorage.setItem(tokenUpdateTimeSec, (Date.now()).toString());
  token = idToken;
  window.localStorage.setItem(idTokenName, idToken);
}
export function getToken() {
  const updateTime = parseInt(window.localStorage.getItem(tokenUpdateTimeSec) || "0");
  if(Date.now() - updateTime > 3*60*60*1000) return null;
  return window.localStorage.getItem(idTokenName);
}
function getHeader(base64Str: string, authType?: string) {
  return {
    'Content-Type': "application/json",
    "Authorization": `${authType ? authType : "Bearer"} ${base64Str}`,
  }
}
export function makePostRequest(url: string, body: object) {
    return new Promise((resolve, reject) => {
      axios.post(baseUrl+url, body, {headers: getHeader(token), timeout: 30000})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
            if (error.code && error.code === "ECONNABORTED") {
                reject(errorMessage.TIME_OUT);
            } else if (error.response && error.response.status) {
                const errorStatus = error.response.status;
                reject(errorMessage[`ERROR_${errorStatus}`]);
            }
        });
    })
}

export function makeGetRequest(url: string, token: string, authType?: string) {
    return new Promise((resolve, reject) => {
        axios.get(baseUrl+url, {headers: getHeader(token, authType), timeout: 30000})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
            if (error.code && error.code === "ECONNABORTED") {
                reject(errorMessage.TIME_OUT);
            } else if (error.response && error.response.status) {
                const errorStatus = error.response.status;
                reject(errorMessage[`ERROR_${errorStatus}`]);
            }
        });
    })
}

export async function downloadTrainingCert(url: string, body: {trainingName: string, trainingId: string}): Promise<{success: boolean, err: string}>{
  try{
      var reqHeaders: any = getHeader(token);
      reqHeaders.Accept = "application/pdf";
      const response = await axios.post(baseUrl+url, body, {headers: reqHeaders, responseType: "arraybuffer"});
      if(response.status !== 200) return {success: false, err: response.data ? response.data : errorMessage[`ERROR_${response.status}`]};
      const internalUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = internalUrl;
      link.setAttribute('download', 'training_cert.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
  } catch (error){
    console.log(error);
    return {success: false, err: "Failed to download certificate. Please contact administrator."}
  }
  return {success: true, err: ""}
}