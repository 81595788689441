import React from 'react';
import {Route, Switch} from "react-router-dom";
import {TrainingList} from "./trainings/trainingList";
import {CurrentTraining} from "./trainings/currentTraining"
export function Dashboard() {
    return (
        <Switch>
            <Route exact path="/" component={TrainingList}/>
            <Route path="/training" component={CurrentTraining}/>
        </Switch>
    );
}