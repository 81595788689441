import React, {useContext, useState} from 'react';
import {Status, getFormattedDate} from "../utils";
import "./styles.css";
import {Link } from 'react-router-dom';
import {FaBan, FaHourglassHalf, FaPlay, FaCheck, FaDownload, FaSpinner} from 'react-icons/fa'
import {IconContext} from 'react-icons'
import { downloadTrainingCert } from '../../ApiCalls';
import {IUserInformationContext} from "../../models/interfaces";
import {UserInformationContext} from "../../Context";
import {getTranslatedLanguage, websiteTexts} from "../../utilities/languageHandler";
interface IProps {
    trainingName: string;
    status: Status;
    lastDate: string;
    priorityRank: number;
    trainingIndex: number;
    trainingId: string;
}

export function MetaTrainingDetails(props: IProps) {
    const styles: any = {
        [Status.INCOMPLETE]: {
            color: "#000000",
            toolTipTitle: websiteTexts.CLICK_TO_START,
            icon: <FaPlay/>,
            iconColor: "orange"
        }, 
        [Status.UPCOMING]: {
            color: "#000000",
            toolTipTitle: websiteTexts.TRAINING_IS_UPCOMING,
            icon: <FaHourglassHalf/>,
            iconColor: "#3232ff"
        },
        [Status.EXPIRED]: {
            color: "#000000",
            toolTipTitle: websiteTexts.TRAINING_IS_EXPIRED,
            icon: <FaBan/>,
            iconColor: "#c0c0c0"
        },
        [Status.COMPLETED]: {
            color: "#000000",
            toolTipTitle: websiteTexts.CLICK_TO_DOWNLOAD_CERT,
            icon: <FaCheck/>,
            iconColor: "#008000"
        }
    }

    const [waiting, setWaiting] = useState(false)

    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);

    function isRedirectable(status: string) {
        return !(status === Status.EXPIRED || status === Status.UPCOMING || status ===  Status.COMPLETED)
    }
    async function linkOnClick(status: string, _trainingName: string, _trainingId: string){
        if(status === Status.COMPLETED){
            setWaiting(true);
            try{ await downloadTrainingCert("/getCert", {trainingName: _trainingName, trainingId: _trainingId}) }
            finally { setWaiting(false) }
        }
    }

    function getDownloadComponent(waiting: boolean): JSX.Element {
        if(waiting) return <span><FaSpinner className="fa-spin"/> {getTranslatedLanguage(websiteTexts.DOWNLOADING, getUserInformationContext.currentLanguage)} </span>
        else return <span> <FaDownload/>{getTranslatedLanguage(websiteTexts.DOWNLOAD_CERTIFICATE, getUserInformationContext.currentLanguage)}</span>
    }

    return (
        <Link to={{pathname: `${isRedirectable(props.status) ? "/training" : ""}`, state: {trainingIndex: props.trainingIndex}}}
            style={{ textDecoration: "none", color: "black"}} onClick={async () => {await linkOnClick(props.status, props.trainingName, props.trainingId)}}
        >
            <div className="box mt-2 mb-2"
                data-toggle="tooltip" title={getTranslatedLanguage(styles[props.status].toolTipTitle, getUserInformationContext.currentLanguage)}
            >
                <div className="float-left training-box-icon" >
                <IconContext.Provider value={{ color: styles[props.status].iconColor, size:"1.1em" }}>
                    {styles[props.status].icon}
                </IconContext.Provider>
                </div>

                    <div className="float-left training-box-text">
                        {props.trainingName}
                    </div>
                    <div className="float-right training-box-info">
                        {props.status === Status.COMPLETED ?
                        getDownloadComponent(waiting)
                        :`${getTranslatedLanguage(websiteTexts.LAST_DATE, getUserInformationContext.currentLanguage)}: ${getFormattedDate(props.lastDate)}`}
                    </div>
            </div>
        </Link>
    );
}