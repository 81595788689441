import React, {useContext} from 'react';
import './styles.css';
import {IUserInformationContext} from "../models/interfaces";
import {UserInformationContext} from "../Context";

export const Footer = () => {
    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);

    function handleLanguageChange(language: string) {
        getUserInformationContext.handleLanguageChange(language);
    }
    return (
        <div className="footer">
            © 2019 UCS. All Rights Reserved.
            <div className="float-right">
                <span className="mr-2">Language/भाषा/ಭಾಷೆ</span>
                <select name="language-dropdown" id="language-dropdown" value = {getUserInformationContext.currentLanguage} onChange={(e) => handleLanguageChange(e.target.value)}>
                    <option value="ENGLISH">English</option>
                    <option value="BENGALI">বাংলা</option>
                    <option value="HINDI">हिंदी</option>
                    <option value="KANNADA">ಕನ್ನಡ</option>
                    <option value="TAMIL">தமிழ்</option>
                </select>
            </div>
        </div>
    )
}
