import React from "react";
import {PageTypes} from "../../utils";
import "./styles.css";
import {getTranslatedLanguage, websiteTexts} from "../../../utilities/languageHandler";

interface IProps {
    pageIndex: number;
    handleClick: any;
    pageType: PageTypes;
    currentPageIndex: number;
    navigablePageIndexes: number[];
    currentLanguage: string;
}

export function SideNav(props: IProps) {

    function getPageNavText(pageIndex: number, pageType: PageTypes): string{
        return pageType === PageTypes.WELCOME ? getTranslatedLanguage(websiteTexts.WELCOME, props.currentLanguage) :
            `${getTranslatedLanguage(websiteTexts.PAGE, props.currentLanguage)} ${pageIndex}`
    }
    return (
        <div className={`page mt-2 mb-2 ${props.pageIndex === props.currentPageIndex ? "page-selected" : ""}`}
            onClick={() => props.handleClick(props.pageIndex, props.navigablePageIndexes)}
        >
            <b>{getPageNavText(props.pageIndex, props.pageType)}</b>
        </div>
    )
} 