export default null;
const translatedTexts = require("./language.json");

export enum languageTypes {
    ENGLISH = "ENGLISH",
    BENGALI = "BENGALI",
    HINDI = "HINDI",
    KANNADA = "KANNADA"
}
export enum websiteTexts {
    LOGIN = "LOGIN",
    LOADING = "LOADING",
    PLEASE_ENTER_YOUR_USER_ID = "PLEASE_ENTER_YOUR_USER_ID",
    PLEASE_ENTER_YOUR_PASSWORD = "PLEASE_ENTER_YOUR_PASSWORD",
    PENDING_TRAININGS = "PENDING_TRAININGS",
    COMPLETED_TRAININGS = "COMPLETED_TRAININGS",
    UPCOMING_TRAININGS = "UPCOMING_TRAININGS",
    EXPIRED_TRAININGS = "EXPIRED_TRAININGS",
    NEXT = "NEXT",
    WELCOME = "WELCOME",
    PAGE = "PAGE",
    WELCOME_TO_THE = "WELCOME_TO_THE",
    PLEASE_WATCH_THE_VIDEO = "PLEASE_WATCH_THE_VIDEO",
    SUBMITTING = "SUBMITTING",
    SUBMIT = "SUBMIT",
    THANKYOU_TRAINING_COMPLETION = "THANKYOU_TRAINING_COMPLETION",
    DO_YOU_HAVE_QUS = "DO_YOU_HAVE_QUS",
    PLEASE_WAIT_WHILE_LOADING = "PLEASE_WAIT_WHILE_LOADING",
    ARE_YOU_SURE_YOU_DONT_HAVE_ANY_QUESTION_FOR_US = "ARE_YOU_SURE_YOU_DONT_HAVE_ANY_QUESTION_FOR_US",
    I_WOULD_LIKE_TO_ASK = "I_WOULD_LIKE_TO_ASK",
    I_HAVE_NO_QUESTION = "I_HAVE_NO_QUESTION",
    TOTAL_PAGES = "TOTAL_PAGES",
    HI = "HI",
    LOGOUT = "LOGOUT",
    DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE",
    DOWNLOADING = "DOWNLOADING",
    QUESTION = "QUESTION",
    YOU_NEED_TO_ANS_ALL_QUESTIONS = "YOU_NEED_TO_ANS_ALL_QUESTIONS",
    EXPLANATION = "EXPLANATION",
    REATTEMPT = "REATTEMPT",
    YOUR_SCORE = "YOUR_SCORE",
    QUIZ_COMPLETION_MESSAGE = "QUIZ_COMPLETION_MESSAGE",
    QUIZ_REATTEMPT_MESSAGE = "QUIZ_REATTEMPT_MESSAGE",
    LAST_DATE = "LAST_DATE",
    CLICK_TO_START = "CLICK_TO_START",
    TRAINING_IS_UPCOMING = "TRAINING_IS_UPCOMING",
    TRAINING_IS_EXPIRED =  "TRAINING_IS_EXPIRED",
    CLICK_TO_DOWNLOAD_CERT = "CLICK_TO_DOWNLOAD_CERT"
}
export function getTranslatedLanguage(text: websiteTexts, language: string) {
    return translatedTexts[text].hasOwnProperty(language) ? translatedTexts[text][language] : translatedTexts[text][languageTypes.ENGLISH]
}
