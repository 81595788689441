import React, { useState } from 'react';
import {Header} from '../Constants/header';
import {Footer} from '../Constants/footer';
import {UserInformationContext} from "../Context";
import {LoginPage} from "../pages/login";
import {Dashboard} from "../pages/dashboard";
import {IUserInfo} from "../models/interfaces";
import {
  HashRouter as Router,
  Route,
} from "react-router-dom";

import "./styles.css";
import {languageTypes} from "../utilities/languageHandler";

function App() {
  let [userInfo, updateUserInfo] = useState<IUserInfo | null>(null);
  let [currentLanguage, handleLanguageChange] = useState<string>(languageTypes.ENGLISH);

    function handleUpdateUserInfo(response: IUserInfo) {
    updateUserInfo((prevState) => {
      return (response);
    })
  }
  function getComponent() {
    if (userInfo) {
      return (
        <Router>
          <Route>
            <Dashboard/>
          </Route>
        </Router>
      )
    } else {
      return <LoginPage/>
    }
  }

  return (
    <div> 
      <UserInformationContext.Provider value={{userInfo, updateUserInfo: handleUpdateUserInfo,
          currentLanguage: currentLanguage, handleLanguageChange: handleLanguageChange}}>
          <Header/>
          <div className="middle-section">
            {getComponent()}
          </div>
          <Footer/>
      </UserInformationContext.Provider>
    </div>
  );
}

export default App;
