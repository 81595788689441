import React, {useState, useContext, useEffect} from 'react';
import {TextField} from "../utilities/inputFunctions";
import logInHero from "../images/debbie-hello.png";
import mascotCache from "../images/debbiefull.png"
import {validateLogin, validationTemplate, Fields} from "./utils";
import {makeGetRequest, saveToken, getToken} from "../ApiCalls";
import {UserInformationContext} from "../Context";
import {IUserCredentials, IUserInformationContext} from "../models/interfaces";
import {getTranslatedLanguage, websiteTexts} from "../utilities/languageHandler";
import "./styles.css";

export function LoginPage() {
    let [loading, handleLoading] = useState<boolean>(false);
    let [userCredentials, handleChangeUserCredential] = useState<IUserCredentials>({userId: null, password: null});
    let [loginError, handleloginError] = useState<validationTemplate>({status: false, type: null, message: null});
    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);

    function onChangeHandler(fieldName: string, value: string | number) {
        handleChangeUserCredential((prevState) => {
            return ({
                ...prevState,
                [fieldName]: value
            });
        })
    }
    function handleLogin(token: string, authType?: string) {
        handleLoading(true);
        let validateLogin = makeGetRequest("/getUserInfo", token, authType);
        validateLogin.then((response: any) => {
            getUserInformationContext.updateUserInfo(response.data);
            saveToken(response.data.token);
        });
        validateLogin.catch((error) => {
            handleLoading(false);
            handleloginError({
                status: false,
                type: "LOGIN_ERROR",
                message: error
            });
        })
    }
    function handleSubmit() {
        const validation: validationTemplate = validateLogin(userCredentials);
        if (validation.status) {
            const base64String: string = window.btoa(userCredentials.userId+":"+userCredentials.password);
            handleLogin(base64String, "Basic");
        } else {
            handleloginError({...validation});
        }
    }
    useEffect(() => {
        if (getToken()) {
            let base64String: any = getToken();
            handleLogin(base64String)
        }
    }, []);
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6 text-center">
                    <img src={logInHero} alt="Login Hero" className="width-100"/>
                    <img src={mascotCache} style={{display:"none"}}/> 
                </div>
                <div className="col-sm-6 pl-4 pr-4 mt-4">
                    <TextField
                        type= "text"
                        value = {userCredentials.userId}
                        onChange = {onChangeHandler}
                        fieldName = 'userId'
                        label = {getTranslatedLanguage(websiteTexts.PLEASE_ENTER_YOUR_USER_ID, getUserInformationContext.currentLanguage)}
                        placeholder={"user@company"}
                        error = {loginError.type === Fields.USER_ID ? loginError.message : null}
                    />

                    <TextField
                        type = "password"
                        value = {userCredentials.password}
                        onChange = {onChangeHandler}
                        fieldName = "password"
                        label = {getTranslatedLanguage(websiteTexts.PLEASE_ENTER_YOUR_PASSWORD, getUserInformationContext.currentLanguage)}
                        error = {loginError.type === Fields.PASSWORD ? loginError.message : null}
                    />
                    {loginError.type === "LOGIN_ERROR" &&
                        <div className="error-color">
                            {loginError.message}
                        </div>
                    }
                    <button className="submit-login mt-4"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? getTranslatedLanguage(websiteTexts.LOADING, getUserInformationContext.currentLanguage)
                            : getTranslatedLanguage(websiteTexts.LOGIN, getUserInformationContext.currentLanguage)}
                    </button>
                </div>
            </div>
        </div>
    )
}