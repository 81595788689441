import React, {useContext, useState} from 'react';
import Logo from "../images/logo.png";
import './styles.css'
import {UserInformationContext} from "../Context";
import {idTokenName} from "../ApiCalls/index";
import {getTranslatedLanguage, websiteTexts} from "../utilities/languageHandler";
import {IUserInformationContext} from "../models/interfaces";

const maxNameLen = 30

export function Header() {
    let [isDropdownOpen, handleDropdown] = useState<boolean>(false);
    function handleLogout() {
        window.localStorage.removeItem(idTokenName);
        window.location.reload();
    }
    const getUserInformationContext: IUserInformationContext = useContext(UserInformationContext);
    return (
        <div className="header">
            <a href="/#/"> <img src={Logo} alt="UCS" style={{width: "auto", height: "7vh", maxHeight: "70px"}}/> </a>
            {   getUserInformationContext.userInfo &&
                <table className="float-right user-info-table">
                    <tbody>
                    <tr>
                        <td>
                        <span className="float-left mr-3">
                            {getUserInformationContext.userInfo.company.name.slice(0, maxNameLen)}
                            {getUserInformationContext.userInfo.company.name.length > maxNameLen ? "..." : ""}
                        </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <span className="float-left mt-1 mr-3">
                            <i className="fa fa-user mr-1" aria-hidden="true"/>
                            {getTranslatedLanguage(websiteTexts.HI, getUserInformationContext.currentLanguage)}, <b>{getUserInformationContext.userInfo.name.slice(0, maxNameLen-6)}</b>
                        </span>

                        <span className="cursor-pointer float-right mt-1 mr-3"
                            onClick={() => handleDropdown(!isDropdownOpen)}>
                        <i className="fa fa-caret-down" aria-hidden="true"/>
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }

            {/*Dropdown content*/}
            <div className="header-dropdown mt-1" style={{display: isDropdownOpen ? "block" : "none"}}>
                <div className="dropdown-section">
                    <div className="option" onClick={handleLogout}>
                        <i className="fa fa-sign-out" aria-hidden="true"/>
                        {getTranslatedLanguage(websiteTexts.LOGOUT, getUserInformationContext.currentLanguage)}
                    </div>
                </div>
            </div>
        </div>
    )
}
